import {
  QueryClientProvider,
  QueryClient as ReactQueryClient,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { AppProps } from "next/app";
import "pretendard/dist/web/static/pretendard.css";
import "pretendard/dist/web/variable/pretendardvariable-dynamic-subset.css";
import { useEffect, useState } from "react";
import "react-spring-bottom-sheet/dist/style.css";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "core/styles/global-style";
import "core/styles/styles.scss";
import { theme } from "core/styles/theme";
// import SentryUtil from "core/utils/sentry";

// SentryUtil.init();

const queryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
    setHydrated(true);
  }, []);
  if (!hydrated) {
    // Returns null on first render, so the client and server match
    return null;
  }
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Component {...pageProps} />
          <ReactQueryDevtools initialIsOpen={false} />{" "}
        </ThemeProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
}
